<template>
  <v-main>
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>
    <v-snackbar
      v-model="snackbar.showing"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      top
    >
    <div class="text-center">
    {{snackbar.message}}
    </div>
    </v-snackbar>
  </v-main>
  
</template>

<script>
  import {mapState} from 'vuex'
  export default {
    name: 'BaseView',
    computed:{
      ...mapState(['snackbar']),
    },
    watch:{
      async is_visible(visible) {
        if (visible || !this.messageLine.length) return;
        await this.$nextTick();
        setTimeout(() => {
          this.shiftMessage();
        }, 500);
      },
    }
  }
</script>
